@layer base {
    @font-face {
    font-family: "gotham";
    src: url("https://e-nonprofitw.com/edsEnewslettersimages/images/GO_cs_fonts/EriksonUniversity/Gotham-BookItalic.otf") format("ttf"),
    url("https://e-nonprofitw.com/edsEnewslettersimages/images/GO_cs_fonts/EriksonUniversity/Gotham-BookItalic.woff") format("woff"),
    url("https://e-nonprofitw.com/edsEnewslettersimages/images/GO_cs_fonts/EriksonUniversity/Gotham-BookItalic.eot") format("eot");
    font-weight:400;
    font-style:italic;
    }
    
    @font-face {
    font-family: "gotham";
    src: url("https://e-nonprofitw.com/edsEnewslettersimages/images/GO_cs_fonts/EriksonUniversity/GothamBook.otf") format("ttf"),
    url("https://e-nonprofitw.com/edsEnewslettersimages/images/GO_cs_fonts/EriksonUniversity/GothamBook.woff") format("woff"),
    url("https://e-nonprofitw.com/edsEnewslettersimages/images/GO_cs_fonts/EriksonUniversity/GothamBook.eot") format("eot");
    font-weight:400;
    font-style:normal;
    }
    
    @font-face {
    font-family: "gotham";
    src: url("https://e-nonprofitw.com/edsEnewslettersimages/images/GO_cs_fonts/EriksonUniversity/GothamBold.otf") format("ttf"),
    url("https://e-nonprofitw.com/edsEnewslettersimages/images/GO_cs_fonts/EriksonUniversity/GothamBold.woff") format("woff"),
    url("https://e-nonprofitw.com/edsEnewslettersimages/images/GO_cs_fonts/EriksonUniversity/GothamBold.eot") format("eot");
    font-weight:600;
    font-style:normal;
    }

}